<template>
  <div v-if="topic" class="is-hover-grow" @click="onInboxTopicCardClick">
    <div class="columns">
      <div class="column">
        <h4 class="mb-1">{{ topic.title }}</h4>
        <div>
          <span>{{ whoRepliedLast }}</span>
          <InlineDate class="ml-1" :d="lastUpdate" />
        </div>
      </div>
      <div class="column is-align-self-center">
        <UserPill class="mr-2 is-pulled-right" :user="otherUser" />
      </div>
    </div>
  </div>
</template>

<script>
/*TODO
[ ] move topic route under inbox
*/

import UserPill from "@/components/common/UserPill.vue";
import InlineDate from "@/components/common/InlineDate.vue";

export default {
  name: "InboxTopicCard",
  components: {
    InlineDate,
    UserPill
  },
  props: {
    topic: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastUpdate() {
      // most recent reply updated_at or topic's updated_at
      return this.topic === null
        ? null
        : this.topic.posts_aggregate.aggregate.max.updated_at ||
            this.topic.updated_at;
    },
    otherUser() {
      const bind = this.topic.forum.DM_binding[0];
      return bind.user_low.id === this.$auth.user.id
        ? bind.user_high
        : bind.user_low;
    },
    whoRepliedLast() {
      let latestReply = this.topic.recent_posts[
        this.topic.recent_posts.length - 1
      ];
      if (latestReply) {
        return latestReply.author.id === this.$auth.user.id
          ? "You replied"
          : latestReply.author.username + " replied";
      }
      return "Sent";
    }
  },
  methods: {
    onInboxTopicCardClick() {
      this.$router.push({
        name: "dashboard_inbox_topic",
        params: { slug: this.topic.slug || "X", topic_id: this.topic.id }
      });
    }
  }
};
</script>
