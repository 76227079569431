<template>
  <div>
    <EmptyState v-if="topics.length === 0">
      You don't have any direct message conversations.
    </EmptyState>

    <div class="mb-4">
      <div v-for="t in topics" :key="t.id">
        <InboxTopicCard :topic="t" class="boxed p-2 my-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { INBOX_TOPIC_LIST } from "@/models/forums/operations.gql";
import {
  isNotDeleted,
  isDirectMessageTopic
} from "@/models/forums/predicates.js";
import InboxTopicCard from "@/components/dashboard/InboxTopicCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "InboxTab",
  components: {
    EmptyState,
    InboxTopicCard
  },
  data: () => ({
    topics: [],
    totalTopics: 0,
    maxTopics: 40,
    flipCondensed: false
  }),
  computed: {
    orderBy() {
      return [{ last_activity: "desc" }];
    },
    where() {
      return {
        ...isNotDeleted(),
        ...isDirectMessageTopic(this.$auth.user.id)
      };
    }
  },
  apollo: {
    topics: {
      query: INBOX_TOPIC_LIST,
      variables() {
        return {
          where: this.where,
          orderBy: this.orderBy,
          limit: this.maxTopics
        };
      },
      update({ topics, topics_aggregate }) {
        this.$log.info("in topics update", topics, topics_aggregate);
        this.totalTopics = topics_aggregate.aggregate.count;
        return topics;
      }
    }
  }
};
</script>
